import { App, Ref, computed, inject, ref } from 'vue'

const TAILWIND_BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
} as const

type Breakpoints = keyof typeof TAILWIND_BREAKPOINTS;
type BreakpointMediaQueries = Record<Breakpoints, string>;

const convertBreakpointsToMediaQueries = (
  breakpoints = TAILWIND_BREAKPOINTS,
) => {
  const breakpointLabels = Object.keys(breakpoints) as Breakpoints[]
  const breakpointValues = Object.values(breakpoints)

  /*
   *  javascript media queries look quite similar to css
   *  this reduce creates these media queries with the min being the breakpoint
   *  and the max being the next breakpoint - 1
   *  i.e. (min-width: 640px) and (max-width: 767px)
   *  the last xxl breakpoint should only have a min-width
   */
  const mediaQueries = breakpointValues.reduce<BreakpointMediaQueries>(
    (
      convertedBreakpoints,
      value,
      idx,
    ) => {
      const nextValue = breakpointValues[idx + 1]
      const mqString = `(min-width: ${value}px)${
        nextValue ? ` and (max-width: ${nextValue - 1}px)` : ''
      }`
      if (breakpointLabels[idx]) {
        convertedBreakpoints[breakpointLabels[idx]] = mqString
      }
      return convertedBreakpoints
    },
    {} as BreakpointMediaQueries,
  )
  return mediaQueries
}

const addListenersToMediaQueries = (
  mediaQuery: string,
  updateBreakpoint: () => void,
) => {
  const mq = window.matchMedia(mediaQuery)
  const callback = (e: MediaQueryListEvent) => {
    if (e.matches) updateBreakpoint()
  }
  mq.addEventListener('change', callback)
  callback(mq as unknown as MediaQueryListEvent)
}

const pointbreak = (app: App) => {
  let initialized = false
  const pointbreak = ref<Breakpoints | null>(null)
  app.provide('pointbreak', pointbreak)

  if (!initialized) {
    const mediaQueries = convertBreakpointsToMediaQueries()
    for (const [breakpoint, mq] of Object.entries(mediaQueries) as [
      Breakpoints,
      string,
    ][]) {
      const updateCurrentBreakpoint = () => {
        pointbreak.value = breakpoint
      }
      addListenersToMediaQueries(mq, updateCurrentBreakpoint)
    }
    initialized = true
  }
}

export const usePointbreak = () => inject<Ref<Breakpoints | null>>('pointbreak', ref(null))

export function useScreenSize () {
  const pointbreak = usePointbreak()
  const buildScreenSizeComputed = (sizes: Partial<Breakpoints>[]) =>
    computed(() => {
      if (!pointbreak.value) return false
      return sizes.includes(pointbreak.value)
    })

  return {
    isXSmall: buildScreenSizeComputed(['xs']),
    isMobile: buildScreenSizeComputed(['xs', 'sm']),
    isSmall: buildScreenSizeComputed(['xs', 'sm', 'md']),
    isWide: buildScreenSizeComputed(['xxl']),
  }
}

export default pointbreak
